import React,{useState, useEffect} from "react";
import About1 from "../img/about-1.jpg";
import About2 from "../img/about-2.jpg";

import New1 from "../img/New-1.jpg";
import New2 from "../img/NewImage-3.jpg";
import axios from "axios";
import { getColorMode } from "../ColorMode";
import logo from "../img/JVSWheelicon.gif";
import logo1 from "../img/JVSWATER.gif";

const About=()=>{

  const colorvar= getColorMode();


  const var1="-about";
  const var2=colorvar.concat(var1);

  const[data,setData]= useState('');

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/about");
        console.log('Fetched doctor data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

    return(
        <section className={`${var2} container-fluid about py-5 servicei`}>
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="h-100">
                <h4 className="text-primary">{data.Title}</h4>
                <h1 className="display-4 mb-4">
                  {data.Sub_Title}
                </h1>
                <div className="row g-4 mb-4">
                  <div className="col-md-6">
                    <a href="#" className="d-flex aboutp">
                    <img src={logo} className="logoimg"/>
                      <h4 className="mb-0">{data.Point1}</h4>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="d-flex aboutp">
                      <img src={logo1} className="logoimg"/>
                      <h4 className="mb-0">{data.Point2}</h4>
                    </a>
                  </div>
                </div>
                <p className="mb-4 text-justify">
                  {data.Description}
                </p>
                <div className="text-dark mb-4 about-point">
                  <p className="fs-5">
                    <span className="fa fa-check text-primary me-2" /> {data.Check1}
                  </p>
                  <p className="fs-5">
                    <span className="fa fa-check text-primary me-2" /> {data.Check2}
                  </p>
                  <p className="fs-5">
                    <span className="fa fa-check text-primary me-2" /> {data.Check3}
                  </p>
                </div>
                <a className="btn btn-primary py-3 px-4 px-md-5 ms-2" href="/contact">
                  Contact us
                </a>
              </div>
            </div>
            <div className="col-lg-5 wow fadeInRight" data-wow-delay="0.2s">
              <div className="position-relative h-100">
                <img
                  src={data.Image1}
                  className="img-fluid w-100 h-100 new1"
                  style={{ backgroundSize: "contain" }}
                  alt=""
                />
                <div className="bg-white">
                  <div
                    className="position-absolute pt-3 bg-white"
                    style={{ width: "50%", left: 0, bottom: 0 }}
                  >
                    <div className="bg-primary p-4">
                      <h4 className="display-2 mb-0">{data.Experience}</h4>
                      <p className="text-white fs-5 mb-0">years of experience</p>
                    </div>
                  </div>
                  <div
                    className="position-absolute p-3 bg-white pb-0 pe-0"
                    style={{ width: "50%", bottom: 0, right: 0 }}
                  >
                    <img src={data.Image2} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default About;