import React,{ useState, useEffect,useRef} from "react";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Navbar from "./Components/Navbar";
import About from "./Components/About";
import ContactWithMe from "./Components/ContactWithMe";
import Service from "./Components/Services";
import Project from "./Components/Projects";
import Blog from "./Components/Blog";
import Team from "./Components/Team";
import Testimonial from "./Components/Testimonial";
import FAQ from "./Components/FAQ";
import Footer from "./Components/Footer";
import "./bootstrap.min.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import { getColorMode } from "./ColorMode";
import "./dynamic.css";
import axios from "axios";
import WelcomeSection from "./Components/WelcomeSection";
import Loader from "./Components/Loader";
import DemoProjects from "./Components/DemoProjects";
import PService from "./Components/ParallexService";


const Homepage=()=>{

  const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);

  useEffect(()=>{
    document.title="Home | JVS Body Works";
    setIsWelcomeVisible(true);
    const handleVisibility = (entries) => {
      const [entry] = entries;
      setIsDemoProjectsVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleVisibility, {
      root: null,
      threshold: 0.1 
    });

    if (demoProjectsRef.current) {
      observer.observe(demoProjectsRef.current);
    }

    return () => {
      if (demoProjectsRef.current) {
        observer.unobserve(demoProjectsRef.current);
      }
    };
  }, []);


  const colorvar= getColorMode();


  const var1="-mode";
  const var2=colorvar.concat(var1);

  const [isDemoProjectsVisible, setIsDemoProjectsVisible] = useState(false);
  const demoProjectsRef = useRef(null); // Reference for DemoProjects section

  const [isPServiceVisible, setIsPServiceVisible] = useState(false);

  const pServiceRef = useRef(null); 

  useEffect(() => {
    const handleVisibility = (entries) => {
      const [entry] = entries;
      setIsPServiceVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleVisibility, {
      root: null,
      threshold: 0.1,
    });

    if (pServiceRef.current) {
      observer.observe(pServiceRef.current);
    }

    return () => {
      if (pServiceRef.current) {
        observer.unobserve(pServiceRef.current);
      }
    };
  }, []);
  

    return(
      <>
      <Loader/>
        <div className={var2}>
        
  <meta charSet="utf-8" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <meta content="" name="keywords" />
  <meta content="" name="description" />
  {/* Google Web Fonts */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
  <link
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
    rel="stylesheet"
  />
  {/* Icon Font Stylesheet */}
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
    rel="stylesheet"
  />
  {/* Libraries Stylesheet */}
  <link rel="stylesheet" href="lib/animate/animate.min.css" />
  <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
  {/* Customized Bootstrap Stylesheet */}
  <link href="css/bootstrap.min.css" rel="stylesheet" />
  {/* Template Stylesheet */}
  <link href="css/style.css" rel="stylesheet" />
  {/* Spinner Start */}
  {/* <div
    id="spinner"
    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
  >
    <div
      className="spinner-border text-primary"
      style={{ width: "3rem", height: "3rem" }}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div> */}
  {/* Spinner End */}
  {/* Navbar & Hero Start */}
    <Navbar/>
  {/* Navbar & Hero End */}
  {/* Modal Search Start */}
  <div
    className="modal fade"
    id="searchModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content rounded-0">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Search by keyword
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center bg-primary">
          <div className="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              className="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span
              id="search-icon-1"
              className="btn bg-light border nput-group-text p-3"
            >
              <i className="fa fa-search" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Search End */}
  {/* Carousel Start */}
 
  {isWelcomeVisible && <WelcomeSection />}
    <About/>
    <ContactWithMe/>
   
      <PService/>
     
    {/* <Service/> */}
    {/* <Project/> */}
   
              <DemoProjects />
    {/* <Team/> */}
    <Testimonial/>
    <Footer/>

 
 
  <a href="#" className="btn btn-primary btn-lg-square back-to-top">
    <i className="fa fa-arrow-up" />
  </a>
  
</div>
</>
    );
}

export default Homepage;