import React,{useState, useEffect} from "react";
import axios from "axios";
import { getColorMode } from "../ColorMode";

const ContactWithMe =()=>{

  const[data,setData]= useState('');

  const colorvar= getColorMode();

  const var1="-banner";

  const var2=colorvar.concat(var1);

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/contactwithme");
        console.log('Fetched doctor data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

    return(
        <div className={`${var2} container-fluid banner py-5 wow zoomIn`} data-wow-delay="0.2s">
        <div className="banner-design-1" />
        <div className="banner-design-2" />
        <div className="container py-5 contantwithmecontainer">
          <div className="row g-5">
            <div className="col-lg-8">
              <div className="">
                <h4 className="text-white">{data.title}</h4>
                <h1 className=" text-white mb-0">
                  {data.description}
                </h1>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-center justify-content-lg-end h-100">
                <a className="btn btn-primary py-3 px-4 px-md-5 ms-2" href="/contact">
                  EXPLORE MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ContactWithMe;