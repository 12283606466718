import React, { useState, useEffect } from "react";
import axios from "axios";
import { getColorMode } from "../ColorMode";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "./AnimProjects.css";

const Project = () => {
  const [data, setData] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // State to track the active tab index

  const colorvar = getColorMode();
  const var1 = "-project";
  const var2 = colorvar.concat(var1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project"
        );
        console.log("Fetched project data:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);


  
  const [data1, setData1] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project1"
        );
        console.log("Fetched project data:", response.data);
        setData1(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const [data2, setData2] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project2"
        );
        console.log("Fetched project data:", response.data);
        setData2(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const [data3, setData3] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project3"
        );
        console.log("Fetched project data:", response.data);
        setData3(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const [data4, setData4] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project4"
        );
        console.log("Fetched project data:", response.data);
        setData4(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTabIndex((prevIndex) => 
        (prevIndex + 1) % projectItems.length 
      );
    }, 3000); 

    return () => clearInterval(intervalId);
  }, [data]); 

  if (!data) return <div>Loading...</div>;

 
  const projectItems = Object.entries(data)
    .filter(([key]) => !isNaN(key)) 
    .map(([key, project]) => ({
      id: project.Project_id,
      title: project.Title,
      description: project.Description,
      image: project.Image,
      href: project.href,
    }));


    

  return (
    <div className={`${var2} container-fluid projects bg-light py-5`}>
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
            <div>
              <h4 className="text-primary">{data.Title}</h4>
              <h1 className="display-4 mb-4">{data.Subtitle}</h1>
              <p className="mb-5">{data.description}</p>
              <ul className="nav">
                {projectItems.map((project, index) => (
                  <li key={index} className="nav-item bg-white mb-4 w-100">
                    <a
                      className={`d-flex align-items-center h4 mb-0 p-3 ${
                        activeTabIndex === index ? "active" : ""
                      }`}
                      data-bs-toggle="pill"
                      href={`#ProjectsTab-${project.id}`}
                    >
                      <div className="projects-icon btn-md-square bg-primary text-white me-3">
                        <span className="fas fa-bolt small" />
                      </div>
                      <span>{project.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-7 wow fadeInRight" data-wow-delay="0.2s">
            <div className="tab-content">
              {projectItems.map((project, index) => (
                <div
                  key={index}
                  id={`ProjectsTab-${project.id}`}
                  className={`tab-pane fade show p-0 ${
                    activeTabIndex === index ? "active" : ""
                  }`}
                >
                  <div className="projects-item">
                    <img src={project.image} className="img-fluid w-100" alt="" />
                    <div className="projects-content bg-white p-4">
                      <h4 className="mb-3">{project.title}</h4>
                      <p className="mb-4">{project.description}</p>
                      <a className="btn btn-primary py-2 px-4" href={project.href}>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
