import React,{useEffect} from "react";
import Navbar from "./Components/Navbar";
import Service from "./Components/Services";
import FAQ from "./Components/FAQ";
import Testimonial from "./Components/Testimonial";
import ContactWithMe from "./Components/ContactWithMe";
import Footer from "./Components/Footer";
import { getColorMode } from "./ColorMode";
import "./dynamic.css";
// import Service from "./Components/Services1";
import Demo from "./Components/AniServi";
import Loader from "./Components/Loader";


const Servicepage=()=>{

  useEffect(()=>{
    document.title="Service | JVS Body Works"
  },[]);

  const colorvar= getColorMode();


  const var1="-mode";
  const var2=colorvar.concat(var1);

    return(
      <div className={var2}>
      <Loader/>
     <meta charSet="utf-8" />
  <title></title>
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <meta content="" name="keywords" />
  <meta content="" name="description" />
  {/* Google Web Fonts */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
  <link
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
    rel="stylesheet"
  />
  {/* Icon Font Stylesheet */}
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
    rel="stylesheet"
  />
  {/* Libraries Stylesheet */}
  <link rel="stylesheet" href="lib/animate/animate.min.css" />
  <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
  {/* Customized Bootstrap Stylesheet */}
  <link href="css/bootstrap.min.css" rel="stylesheet" />
  {/* Template Stylesheet */}
  <link href="css/style.css" rel="stylesheet" />
  {/* Spinner Start */}
  {/* <div
    id="spinner"
    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
  >
    <div
      className="spinner-border text-primary"
      style={{ width: "3rem", height: "3rem" }}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div> */}
  {/* Spinner End */}
  {/* Navbar & Hero Start */}
    <Navbar/>
    <div className="container-fluid bg-breadcrumb">
  <div className="container text-center py-5" style={{ maxWidth: 900 }}>
    <h4
      className="text-white display-4 mb-4 wow fadeInDown"
      data-wow-delay="0.1s"
    >
      Our Services
    </h4>
    <ol
      className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
      data-wow-delay="0.3s"
    >
      <li className="breadcrumb-item">
        <a href="/">Home</a>
      </li>
      {/* <li className="breadcrumb-item">
        <a href="#">Pages</a>
      </li> */}
      <li className="breadcrumb-item active text-primary">Service</li>
    </ol>
  </div>
</div>

        {/* <Demo/> */}
        <Service/>
        {/* <Service1/> */}
        {/* <Demo/> */}
        <Testimonial/>
        <ContactWithMe/>
        <Footer/>

    </div>
    );
}

export default Servicepage;