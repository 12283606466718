import React, {useState, useEffect} from "react";
import "./DemoServices.css";
import axios from "axios";
import { getColorMode } from "../ColorMode";

const Demo=()=>{

    const [data, setData] = useState("");
    const [commercialData, setCommercialData] = useState([]);
    const [residentialData, setResidentialData] = useState([]);
    const [industrialData, setIndustrialData] = useState([]);
    const [safetyCompliance, setSafetyCompliance] = useState([]);
    const [bgImage, setBgImage] = useState([]);
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Service");
        console.log('Fetched service data:', response.data); 
        setData(response.data);
        setCommercialData(response.data.Commercial);
        setResidentialData(response.data.Residental);
        setIndustrialData(response.data.Industrial);
        setSafetyCompliance(response.data.SafetyCompliance);
        setBgImage(response.data.SlideBgImage);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
    return () => clearInterval(); 
  }, []);

  const colorvar= getColorMode();

  const var1="-service-v2";
  const var2=colorvar.concat(var1);
   
    return(
        <section className={`${var2} section service-v2 serviceanimation`}>
         <div className="servicontainer3">
            <div className="servi" >
            <h4 className="text-primary text-center">{data.Title}</h4>
           
            <p className="mb-4 text-center servip">
            {data.description}
          </p>
                <div className="service-v2-bottom-wrap">
                    <div className="service-v2-sticky-wrap">
                        <div className="service-v2-main-wrap">
                            <div className="service-v2-single-wrap section1" style={{ backgroundImage: `url(${commercialData[1]?.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrap">
                                    <div className="col-lg-6">
                                    
                                    <div className={`tab-pane fade show active`}>
                                        <div className="item">
                                            <img src={commercialData[1]?.Image} className="img-fluid w-100" alt="" />
                                            {/* <div className="border border-top-0 p-4">
                                            <h4 className="mb-3">{commercialData[1]?.Title}</h4>
                                            <p className="mb-4">{commercialData[1]?.description}</p>
                                            {/* <a className="btn btn-primary py-2 px-4" href="#">
                                                Read More
                                            </a> 
                                        </div> */}
                                        </div>   
                                        </div>
                                        
                                        </div>
                                        <div className="commericalContent p-4">            
                                            <h4 className="text-center mb-4">{commercialData[1]?.Title}</h4><hr/>
                                            <p className="mb-4">{commercialData[1]?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            <div className="service-v2-single-wrap section2" style={{ backgroundImage: `url(${residentialData[1]?.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrap">
                                <div className="col-lg-6">
                                    <div className={`tab-pane fade show active`}>
                                        <div className="item">
                                            <img src={residentialData[1]?.Image} className="img-fluid w-100" alt="" />
                                           
                                        </div>
                                        </div>
                                    </div>
                                    <div className="residentalContent  p-4"> 
                                        <h4 className="text-center mb-3">{residentialData[1]?.Title}</h4><hr/>
                                        <p className="mb-4">{residentialData[1]?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="service-v2-single-wrap section3" style={{ backgroundImage: `url(${industrialData[1]?.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrap">
                                <div className="col-lg-6">
                                    <div className={`tab-pane fade show active`}>
                                        <div className="item">
                                            <img src={industrialData[1]?.Image} className="img-fluid w-100" alt="" />
        
                                        </div>   
                                        </div>
                                    </div>
                                    <div className="industrialContent  p-4"> 
                                        <h4 className="text-center mb-3">{industrialData[1]?.Title}</h4><hr/>
                                        <p className="mb-4">{industrialData[1]?.description}</p>           
                                    </div>
                                </div>
                            </div>
                            <div className="service-v2-single-wrap section4" style={{ backgroundImage: `url(${safetyCompliance[1]?.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrap">
                                <div className="col-lg-6">
                                    <div className={`tab-pane fade show active`}>
                                        <div className="item">
                                        <img src={safetyCompliance[1]?.Image} className="img-fluid w-100" alt="" />
                                            <div className="border border-top-0 p-4">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="safetyContent  p-4"> 
                                    <h4 className="text-center mb-3">{safetyCompliance[1]?.Title}</h4><hr/>
                                    <p className="mb-4">{safetyCompliance[1]?.description}</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        </section>
    );
}

export default Demo;