import React, { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import { getColorMode } from "../ColorMode";

const Service = () => {
  const [activeTab, setActiveTab] = useState("ServiceTab-1");
  const [data, setData] = useState("");
  const [commercialData, setCommercialData] = useState([]);
  const [residentialData, setResidentialData] = useState([]);
  const [industrialData, setIndustrialData] = useState([]);
  const [safetyCompliance, setSafetyCompliance] = useState([]);

  const tabIds = ["ServiceTab-1", "ServiceTab-2", "ServiceTab-3", "ServiceTab-4"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Service");
        console.log('Fetched service data:', response.data); 
        setData(response.data);
        setCommercialData(response.data.Specifi.Commercial);
        setResidentialData(response.data.Specifi.Residental);
        setIndustrialData(response.data.Specifi.Industrial);
        setSafetyCompliance(response.data.Specifi.SafetyCompliance);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();

   
    const interval = setInterval(() => {
      setActiveTab(prevTab => {
        const currentIndex = tabIds.indexOf(prevTab);
        const nextIndex = (currentIndex + 1) % tabIds.length;
        document.getElementById(tabIds[nextIndex]).scrollIntoView({ behavior: "smooth" });
        return tabIds[nextIndex];
      });
    }, 3000);

    return () => clearInterval(interval); 
  }, []);

  useEffect(() => {
    
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveTab(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    tabIds.forEach(tabId => {
      const element = document.getElementById(tabId);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      tabIds.forEach(tabId => {
        const element = document.getElementById(tabId);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    const tabPane = document.getElementById(tabId);
    tabPane.scrollIntoView({ behavior: "smooth" });
  };

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      }
    },
  };

  return (
    <div className="container-fluid service py-5">
      <div className="container py-5">
        <div
          className="d-flex flex-column mx-auto text-center mb-5 wow fadeInUp"
          data-wow-delay="0.2s"
          style={{ maxWidth: 800 }}
        >
          <h4 className="text-primary">{data.Title}</h4>
          <h1 className="display-4 mb-4 text-black">{data.SubTitle}</h1>
          <p className="text-black mb-0">
            {data.description}
          </p>
        </div>
        <div className="row g-5 align-items-center">
          <div className="col-lg-3 wow fadeInLeft" data-wow-delay="0.2s">
            <ul className="nav">
              {tabIds.map((tabId, index) => (
                <li className="nav-item me-lg-0 mb-4" key={tabId}>
                  <a
                    className={`py-3 px-5 ${activeTab === tabId ? "active" : ""}`}
                    onClick={() => handleTabClick(tabId)}
                  >
                    <span>{["Custom Fabrication", "Maintenance and Repairs", "Consultation", "Safety Compliance"][index]}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-9 wow fadeInRight" data-wow-delay="0.3s">
            <div className="tab-content">
              {tabIds.map((tabId, index) => (
                <div
                  key={tabId}
                  id={tabId}
                  className={`tab-pane fade show ${activeTab === tabId ? "active" : ""}`}
                >
                  <OwlCarousel className="owl-theme" {...options}>
                    {(index === 0 ? commercialData : index === 1 ? residentialData : index === 2 ? industrialData : safetyCompliance)?.map((item, i) => (
                      item && (
                        <div className="item" key={i}>
                          <img src={item.Image} className="img-fluid w-100" alt="" />
                          <div className="border border-top-0 p-4">
                            <h4 className="mb-3">{item.Title}</h4>
                            <p className="mb-4 text-black">{item.description}</p>
                            {/* <a className="btn btn-primary py-2 px-4" href="#">
                              Read More
                            </a> */}
                          </div>
                        </div>
                      )
                    ))}
                  </OwlCarousel>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
