import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Loader from "./Components/Loader";

const PetroleumTankers=()=>{

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/PetroleumTankers"
        );
        console.log("Fetched project data:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);


  if (!data) {
    return <div></div>;
  }

    return(
        <>
        <Loader/>
        <Navbar/>
        
         {/* Icon Font Stylesheet */}
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
    rel="stylesheet"
  />
            
            
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{ maxWidth: 900 }}>
                    <h4
                    className="text-white display-4 mb-4 wow fadeInDown"
                    data-wow-delay="0.1s"
                    >
                    Our Product
                    </h4>
                    <ol
                    className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
                    data-wow-delay="0.3s"
                    >
                    <li className="breadcrumb-item">
                        <a href="/">Home</a>
                    </li>
                    {/* <li className="breadcrumb-item">
                        <a href="#">Pages</a>
                    </li> */}
                    <li className="breadcrumb-item active text-primary">Petroleum Tankers</li>
                    </ol>
                </div>
                </div><br></br>
                <div className="overview-block-ptb  white-bg" style={{ marginTop: "-16px" }}>
              <div className="container iq-mt-50">
                <div className="row iq-mt-50">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="heading-title jcbl-home-align">
                      <h2 className="iq-tw-3 text-primary text-center ">{data.Title}​</h2>
                      <h4 className="iq-tw-4 text-dark text-center">{data.Sub_title}</h4>
                    </div>
                  </div>
                </div>
                <div className="row  justify-content-center">
                  <div className="col-sm-11 text-left">
                    <p className="">
                    {data.Fulldes1}
                    </p>
                    <p className="">
                    {data.Fulldes2}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 iq-mtb-15 px-5">
                    {/* Nav tabs */}
                    <div className="feature-aria home-align">
                      <img
                        alt=""
                        className="img-fluid fimg"
                        data-wow-duration="1s"
                        src={data.Image}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-md-8 col-sm-12 iq-mtb-40 px-5"
                    style={{ textAlign: "initial" }}
                  >
                    <h4 className="iq-tw-4 text-dark iq-mb-15">Types of tankers</h4>
                    <ul>
                    {data.Types && data.Types.map((type, index) => type && <li key={index}>{type}</li>)}
                    </ul>
                    <h4 className="iq-tw-4 text-dark iq-mt-30 iq-mb-15">
                      Why choose our Petroleum Tankers?
                    </h4>
                    <ul>
                    {data.Why && data.Why.map((reason, index) => reason && <li key={index}>{reason}</li>)}
                    </ul>
                  </div>
                  <div className="clearfix">&nbsp;</div>
                </div>
                <div className="heading-title text-center">
                  <h2 className="iq-tw-3 text-dark iq-ml-10">
                    Salient features of JVS Body Works
                  </h2>
                </div>
                <div className="row  justify-content-left jcbl-row">
                  <div className="col-lg-6 col-md-6 col-sm-12 iq-mtb-40 px-5">
                  <ul>
                    {data.KeyFeatures &&
                      data.KeyFeatures.map(
                        (feature, index) =>
                          feature && (
                            <li key={index}>
                              <span style={{ fontWeight: 'bold', color: 'black' }}>{feature.Name}</span>: {feature.Value}
                            </li>
                          )
                      )}
                  </ul>

                  </div>
                </div>
                {/* <p className="text-center iq-tw-2 iq-font-size-40">
                  *Actual product specifications and design may vary as per customer
                  requirement
                </p> */}
              </div>
            </div>

            <Footer/>
        </>
    );
}

export default PetroleumTankers;