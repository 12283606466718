import React,{useState, useEffect} from "react";
import Demo from "./Components/AniServi";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import DemoProduct from "./Components/DemoProduct";
import Loader from "./Components/Loader";

const DemoPage=()=>{
    return(
        <>
            <Navbar/>
            <Loader/>
            <Demo/>
            <DemoProduct/>
            <Footer/>
        </>
    );
}

export default DemoPage;