import React, { useState, useEffect } from "react";
import './SlideAdmin.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from "axios";

const SlideAdmin = () => {
  const [inputFields, setInputFields] = useState([{ field1: '', field2: '', field3: '', image: null }]);
  const [slideSpeed, setSlideSpeed] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get('https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/fetchslides');
        if (response.status === 200) {
          const { slideSpeed, slides } = response.data;
          setSlideSpeed(slideSpeed);
          setInputFields(slides.map(slide => ({
            field1: slide.field1,
            field2: slide.field2,
            field3: slide.field3,
            imageUrl: slide.imageUrl
          })));
        }
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };

    fetchSlides();
  }, []);

  const handleAddFields = () => {
    setInputFields([...inputFields, { field1: '', field2: '', field3: '', image: null }]);
  };

  const handleDeleteFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, e) => {
    const newFields = [...inputFields];
    newFields[index][e.target.name] = e.target.value;
    setInputFields(newFields);
  };

  const handleFileChange = (index, e, imageType) => {
    const newFields = [...inputFields];
    newFields[index][imageType] = e.target.files[0];
    setInputFields(newFields);
  };

  const handleSlideSpeedChange = (e) => {
    setSlideSpeed(e.target.value);
  };

  const uploadImage = async (image) => {
    if (!image) return null;
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`WelcomeSlide/${image.name}`);
    await imageRef.put(image);
    const imageUrl = await imageRef.getDownloadURL();
    return imageUrl;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSuccessMessage('');
  
    try {
      const uploadedData = await Promise.all(
        inputFields.map(async (field) => {
          const imageUrl = await uploadImage(field.image);
          const imageUrl1 = await uploadImage(field.image1);
          return {
            field1: field.field1,
            field2: field.field2,
            field3: field.field3,
            imageUrl: imageUrl || field.imageUrl,
          };
        })
      );
  
      const slideData = {
        slideSpeed,
        slides: uploadedData,
      };
  
      const response = await axios.post('https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/slides', slideData);
  
      if (response.status === 200) {
        setSuccessMessage('Form Submitted Successfully!');
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <h3 className="text-center text-orange">Welcome Section Slide Settings</h3>
      <div className="dynamic-fields-section">
        {inputFields.map((inputField, index) => (
          <div key={index} className="input-group">
            <div className="field-group">
             
              <input
                type="text"
                name="field1"
                value={inputField.field1}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Title"
              />
            </div>
            <div className="field-group">
            
              <input
                type="text"
                name="field2"
                value={inputField.field2}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Sub Title"
              />
            </div>
            <div className="field-group">
              
              <input
                type="text"
                name="field3"
                value={inputField.field3}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Description"
              />
            </div>
            <div className="field-group">
              <label>Bg Image:</label>
              <input
                type="file"
                name="image"
                onChange={(e) => handleFileChange(index, e, 'image')}
              />
            </div>
            <button
              onClick={() => handleDeleteFields(index)}
              className="delete-button"
            >
              Delete
            </button>
            {inputField.imageUrl && (
              <img src={inputField.imageUrl} alt={`Slide ${index}`} style={{ width: '100px', height: 'auto' }} />
            )}
          </div>
        ))}
        <button onClick={handleAddFields} className="add-more-button">+</button>
      </div>
      <div className="input-group mb-4">
        <label htmlFor="slideSpeed" className="slide-speed-label">Slide Speed:</label>
        <input
          type="text"
          id="slideSpeed"
          name="slideSpeed"
          value={slideSpeed}
          onChange={handleSlideSpeedChange}
          placeholder="Enter slide speed"
          className="slide-speed-input"
        />
      </div>
      <button onClick={handleSubmit} className="submit-button mb-4" disabled={isSubmitting}>
        Submit
      </button>
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default SlideAdmin;
