import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getColorMode } from "../ColorMode";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "./AnimProjects.css";
import logo from "../img/JVSLogo-Ico.ico";
import Logotruck from "../img/Logotruck.gif";

const DemoProjects = () => {
  const [data, setData] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const colorvar = getColorMode();
  const var1 = "-project";
  const var2 = colorvar.concat(var1);
  const demoProjectsRef = useRef(null);
  const projectRefs = useRef([]);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project1"
        );
        setData1(response.data);
      } catch (error) {
        console.error("Error fetching data1:", error);
      }
    };
    fetchData1();
  }, []);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project2"
        );
        setData2(response.data);
      } catch (error) {
        console.error("Error fetching data2:", error);
      }
    };
    fetchData2();
  }, []);

  useEffect(() => {
    const fetchData3 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project3"
        );
        setData3(response.data);
      } catch (error) {
        console.error("Error fetching data3:", error);
      }
    };
    fetchData3();
  }, []);

  useEffect(() => {
    const fetchData4 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project4"
        );
        setData4(response.data);
      } catch (error) {
        console.error("Error fetching data4:", error);
      }
    };
    fetchData4();
  }, []);

  
  const [isFixed1, setIsFixed1] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      
      const startFixed = scrollableHeight * 0.56; // 20% scroll point
      const endFixed = scrollableHeight * 0.88;   // 80% scroll point

      if (scrollPosition >= startFixed && scrollPosition <= endFixed) {
        setIsFixed1(true);
      } else {
        setIsFixed1(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const [isFixed, setIsFixed] = useState(false);
  
  useEffect(() => {
    const checkScroll = () => {
      const scrollY = window.scrollY;

     
     

      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollY / totalHeight) * 100;

      // Set the active tab based on scroll percentage
      if (scrollPercent < 62) {
        setActiveTabIndex(0);
      } else if (scrollPercent < 70) {
        setActiveTabIndex(1);
      } else if (scrollPercent < 78) {
        setActiveTabIndex(2);
      } else {
        setActiveTabIndex(3);
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  if (!data) return <div></div>;

  const projectItems = Object.entries(data)
    .filter(([key]) => !isNaN(key))
    .map(([key, project]) => ({
      id: project.Project_id,
      title: project.Title,
      description: project.Description,
      image: project.Image,
      href: project.href,
    }));

    const toggleDescription = () => {
      setIsDescriptionVisible(!isDescriptionVisible);
    };

  return (
    <section ref={demoProjectsRef} className={`${var2} container-fluid projects bg-light py-5`}>
      <div className="container py-5">
        <div className="row g-5">
          <div className={`col-lg-5 wow fadeInLeft projectcontentfixed ${isFixed1 ? 'fixed1' : ''}`} data-wow-delay="0.2s">
            <h4 className="text-primary">{data.Title}</h4>
            <h1 className="display-4">{data.Subtitle}</h1>
            <button className="toggle-btn" onClick={toggleDescription}>
              {isDescriptionVisible ? "-" : "+"}
            </button>

            {/* Conditionally Render Description */}
            <p className={`text-black description-text ${isDescriptionVisible ? "show" : "hide"}`}>
              {data.description}
            </p>
            <ul className="nav">
              {projectItems.map((project, index) => (
                <li key={index} className="nav-item bg-white  w-100">
                  <a
                    className={`d-flex align-items-center h4 mb-0 p-3 ${activeTabIndex === index ? "active" : ""}`}
                    data-bs-toggle="pill"
                    href={`#ProjectsTab-${project.id}`}
                    style={{
                      color: activeTabIndex === index ? 'red' : 'black',
                      fontWeight: visibleIndex === index ? 'normal' : 'normal',
                      backgroundColor: activeTabIndex === index ? 'orange' : 'transparent' // Change background color for active tab
                    }}
                    onClick={() => setActiveTabIndex(index)}
                  >
                    <div className="projects-icon btn-md-square bg-white text-white me-3 projects-div">
                      <img src={Logotruck} className="logoimg"/>
                    </div>
                    <span>{project.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-lg-6 v2d">
            <div className="wow fadeInRight bottom-wrapd" data-wow-delay="0.2s">
              <div className="tab-content sticky-wrapd">
                <div className="tab-pane fade show active project-1" data-index={0} ref={el => projectRefs.current[0] = el}>
                  <div className="projects-item">
                    <img src={data1.Image} className="img-fluid w-100" alt="" />
                    <div className="projects-content bg-white p-4"> 
                      <h4 className="mb-3">{data1.Title}</h4>
                      <p className="mb-4">{data1.Description}</p>
                      <a className="btn btn-primary py-2 px-4" href={data1.href}>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show active project-2" data-index={1} ref={el => projectRefs.current[1] = el}>
                  <div className="projects-item">
                    <img src={data2.Image} className="img-fluid w-100" alt="" />
                    <div className="projects-content bg-white p-4">
                      <h4 className="mb-3">{data2.Title}</h4>
                      <p className="mb-4">{data2.Description}</p>
                      <a className="btn btn-primary py-2 px-4" href={data2.href}>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show active project-3" data-index={2} ref={el => projectRefs.current[2] = el}>
                  <div className="projects-item">
                    <img src={data3.Image} className="img-fluid w-100" alt="" />
                    <div className="projects-content bg-white p-4">
                      <h4 className="mb-3">{data3.Title}</h4>
                      <p className="mb-4">{data3.Description}</p>
                      <a className="btn btn-primary py-2 px-4" href={data3.href}>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show active project-4" data-index={3} ref={el => projectRefs.current[3] = el}>
                  <div className="projects-item">
                    <img src={data4.Image} className="img-fluid w-100" alt="" />
                    <div className="projects-content bg-white p-4">
                      <h4 className="mb-3">{data4.Title}</h4>
                      <p className="mb-4">{data4.Description}</p>
                      <a className="btn btn-primary py-2 px-4" href={data4.href}>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoProjects;
