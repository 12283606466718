import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { FaTrash } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import axios from 'axios'; // Axios to fetch data from backend
import "./Adminpage.css";
import SlideAdmin from "./Components/SlideAdmin";
import Loader from "./Components/Loader";

const AdminPage = () => {
  const [contactFormData, setContactFormData] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]); // Define filteredAppointments state
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/getContactValues");
        console.log('Fetched service data:', response.data); 
        const filteredData = response.data.filter(item => item);
        setContactFormData(filteredData || []);
        setFilteredAppointments(filteredData || []);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filterAppointments = () => {
      let filtered = [...contactFormData];
  
      const parseDate = (dateString) => {
        // Convert DD/MM/YYYY to YYYY/MM/DD for comparison
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
      };
  
      if (fromDate) {
        const fromDateObj = parseDate(fromDate);
        filtered = filtered.filter((appointment) => {
          const appointmentDate = parseDate(appointment.date);
          return !isNaN(appointmentDate) && appointmentDate >= fromDateObj;
        });
      }
  
      if (toDate) {
        const toDateObj = parseDate(toDate);
        // Add 1 day to the `toDate` for comparison to include the selected date
        const adjustedToDate = new Date(toDateObj);
        adjustedToDate.setDate(adjustedToDate.getDate() + 1); // Add one day
  
        filtered = filtered.filter((appointment) => {
          const appointmentDate = parseDate(appointment.date);
          return !isNaN(appointmentDate) && appointmentDate < adjustedToDate;
        });
      }
  
      filtered.sort((a, b) => parseDate(a.date) - parseDate(b.date));
      setFilteredAppointments(filtered);
    };
  
    filterAppointments();
  }, [fromDate, toDate, contactFormData]);
  

  const handleDeleteContactForm = async (Id) => {
    try {
      await axios.delete(`https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/deleteContact/${Id}`);
      // After successful deletion, fetch the data again to update the table
      const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/getContactValues");
      const filteredData = response.data.filter(item => item);
      setContactFormData(filteredData || []);
      setFilteredAppointments(filteredData || []); // Update filteredAppointments after deletion
    } catch (error) {
      console.error("Error deleting contact form:", error);
    }
  };

  return (
    <>
      <Navbar />
      <Loader/>
      <div className="appointments_table_section layout_padding-bottom">
        <div className="container">
          <h2>Contact Form Submissions</h2>
          <div className="mb-3">
            <PDFDownloadLink
              document={<PDFDocument1 contactFormData={filteredAppointments} />} // Use filteredAppointments
              fileName="contact_form_submissions.pdf"
            >
              {({ loading }) => loading ? 'Loading document...' : 'Export in PDF'}
            </PDFDownloadLink>
          </div>
          <div className="date-filters">
            <div className="filter">
              <label>From:</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => {
                    console.log('From Date Changed:', e.target.value); // Debugging log
                    setFromDate(e.target.value);
                }}
                />
            </div>
            <div className="filter">
              <label>To:</label>
              <input
                    type="date"
                    value={toDate}
                    onChange={(e) => {
                        console.log('To Date Changed:', e.target.value); // Debugging log
                        setToDate(e.target.value);
                    }}
                    />
            </div>
          </div>
          <div className="table-responsive">
            <table id="contactFormTable">
              <thead>
                <tr>
                  <th style={{ color: 'black' }}>Date</th>
                  <th style={{ color: 'black' }}>Name</th>
                  <th style={{ color: 'black' }}>Email</th>
                  <th style={{ color: 'black' }}>Contact</th>
                  <th style={{ color: 'black' }}>Project</th>
                  <th style={{ color: 'black' }}>Subject</th>
                  <th style={{ color: 'black' }}>Message</th>
                  <th style={{ color: 'black' }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredAppointments.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.date || 'N/A'}</td>
                    <td>{item?.name || 'N/A'}</td>
                    <td>{item?.email || 'N/A'}</td>
                    <td>{item?.contact || 'N/A'}</td>
                    <td>{item?.project || 'N/A'}</td>
                    <td>{item?.subject || 'N/A'}</td>
                    <td>{item?.message || 'N/A'}</td>
                    <td>
                      <FaTrash
                        onClick={() => handleDeleteContactForm(item?.Id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <SlideAdmin/>
      <Footer />
    </>
  );
};

export default AdminPage;

// PDF Document styles and component remain the same
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    position: 'relative',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'orange',
    backgroundColor: 'orange',
    padding: 5,
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    padding: 5,
    backgroundColor: 'white',
    fontSize: '10px',
  },
});

const PDFDocument1 = ({ contactFormData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text>Name</Text></View>
          <View style={styles.tableColHeader}><Text>Email</Text></View>
          <View style={styles.tableColHeader}><Text>Contact</Text></View>
          <View style={styles.tableColHeader}><Text>Project</Text></View>
          <View style={styles.tableColHeader}><Text>Subject</Text></View>
          <View style={styles.tableColHeader}><Text>Message</Text></View>
        </View>
        {contactFormData.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text>{item ? item.name : 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{item ? item.email : 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{item ? item.contact : 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{item ? item.project : 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{item ? item.subject : 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{item ? item.message : 'N/A'}</Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);
