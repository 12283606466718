import React from "react";
import Image from "../img/FounderImgg.png";
import "./Founder.css";
import { FaMedal, FaGraduationCap } from 'react-icons/fa'; // Import icons
import { getColorMode } from "../ColorMode";

const Founder = () => {

    var colorMode=getColorMode();
    var colorMode1="-foundersection1";
    var colorMode2=colorMode.concat(colorMode1);

    return (
        
        <>
        <div className={`${colorMode2} foundersection1`}>
            <h1 className="text-primary text-center">Founder</h1>
            <div className="container mt-5">
                <div className="row block-9 founder-container">
                    <div className="col-md-4 founder-image">
                        <img src={Image} alt="founder" />
                    </div>
                    <div className="col-md-1 founder-info">
                        <div className="founderinfo mb-4">
                            <h5 className="text-left">At JVS Body Works, we leverage our extensive experience and expertise to deliver top-quality tankers and mobile fuel dispensers tailored to meet the diverse needs of our clients. Founded in 2011 by Bramashir K. Babu, a seasoned industry professional with over 35 years of experience</h5>
                            <h6 className="text-left">Explore our range of advanced tankers and mobile fuel dispensers and discover how our experience and commitment to innovation can meet your transport and dispensing needs</h6>
                            <h6 className="text-left"></h6>
{/*                       
                            <div className="achievements-section glassmorphism">
                                <h5><FaMedal /> Achievements</h5>
                                <ul>
                                    <li>SDAT Champion - 4 Times</li>
                                    <li>Republic games State Champion</li>
                                    <li>Divisional Champion - 4 Times</li>
                                    <li>District Champion - 6 Times</li>
                                    <li>Anna University Silver Medalist</li>
                                    <li>TIES Silver Medalist</li>
                                    <li>Anna Univ TN State Silver Medalist</li>
                                    <li>Zonal Champion - 7 Times (Singles & Doubles)</li>
                                </ul>
                            </div> */}
                         
                            
                        </div>
                      
                    </div>
                   
                </div>
            </div>
        </div>
        </>
    );
}

export default Founder;
