import React, {useState, useEffect} from "react";
import "./DemoProduct.css";
import axios from "axios";
import { getColorMode } from "../ColorMode";

const DemoProduct=()=>{
    const [data, setData] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project"
            );
            console.log("Fetched project data:", response.data);
            setData(response.data);
          } catch (error) {
            console.error("Error fetching data from backend:", error);
          }
        };
        fetchData();
      }, []);
    

      const [data1, setData1] = useState("");

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project1"
            );
            console.log("Fetched project data:", response.data);
            setData1(response.data);
          } catch (error) {
            console.error("Error fetching data from backend:", error);
          }
        };
        fetchData();
      }, []);
    
      const [data2, setData2] = useState("");

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project2"
            );
            console.log("Fetched project data:", response.data);
            setData2(response.data);
          } catch (error) {
            console.error("Error fetching data from backend:", error);
          }
        };
        fetchData();
      }, []);

      const [data3, setData3] = useState("");

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project3"
            );
            console.log("Fetched project data:", response.data);
            setData3(response.data);
          } catch (error) {
            console.error("Error fetching data from backend:", error);
          }
        };
        fetchData();
      }, []);

      const [data4, setData4] = useState("");

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              "https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/project4"
            );
            console.log("Fetched project data:", response.data);
            setData4(response.data);
          } catch (error) {
            console.error("Error fetching data from backend:", error);
          }
        };
        fetchData();
      }, []);
      

      const colorvar= getColorMode();

      const var1="-service-v2d";
      const var2=colorvar.concat(var1);

    return(
        <section className={`${var2} section service-v2d`}>
        <div className="servicontainer3d">
           <div className="servid" >
           <h4 className="text-primary text-center">{data.Title}</h4>
          
           <p className="mb-4 text-center productdesc">
           {data.description}
         </p>
               <div className="service-v2-bottom-wrapd">
                   <div className="service-v2-sticky-wrapd">
                       <div className="service-v2-main-wrap">
                           <div className="service-v2-single-wrapd section1d" style={{ backgroundImage: `url(${data1.SlideBgImage})` }}>
                               <div className="service-v2-flex-wrapd">
                              
                               
                               <div className="projects-content bg-white p-4">
                                  <h4 className="mb-3 text-center">{data1.Title}</h4><hr></hr>
                                  <p className="mb-4">{data1.Description}</p>
                                  <a className="btn btn-primary py-2 px-4" href={data1.href}>
                                    Read More
                                  </a>
                                </div>
                                <img src={data1.Image} className="img-fluid data1Image" alt="" />
                              
                             
                                </div>
                              
                               
                            </div>
                            <div className="service-v2-single-wrap section2d" style={{ backgroundImage: `url(${data2.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrapd">

                            
                               <div className="projects-content1 bg-white p-4">
                                  <h4 className="mb-3 text-center">{data2.Title}</h4><hr></hr>
                                  <p className="mb-4">{data2.Description}</p>
                                  <a className="btn btn-primary py-2 px-4" href={data2.href}>
                                    Read More
                                  </a>
                                </div>
                              <img src={data2.Image} className="img-fluid data1Image" alt="" />
                                </div>
                            </div>
                            <div className="service-v2-single-wrap section3d" style={{ backgroundImage: `url(${data3.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrapd">
                               
                               <div className="projects-content2 bg-white p-4">
                                  <h4 className="mb-3 text-center">{data3.Title}</h4><hr></hr>
                                  <p className="mb-4">{data3.Description}</p>
                                  <a className="btn btn-primary py-2 px-4" href={data3.href}>
                                    Read More
                                  </a>
                                </div>
                              
                
                              <img src={data3.Image} className="img-fluid data3Image" alt="" />
                                </div>
                            </div>
                            <div className="service-v2-single-wrap section4d" style={{ backgroundImage: `url(${data4.SlideBgImage})` }}>
                                <div className="service-v2-flex-wrapd">
                               <div className="projects-content3 bg-white p-4">
                                  <h4 className="mb-3 text-center">{data4.Title}</h4><hr></hr>
                                  <p className="mb-4">{data4.Description}</p>
                                  <a className="btn btn-primary py-2 px-4" href={data4.href}>
                                    Read More
                                  </a>
                                </div>
                              <img src={data4.Image} className="img-fluid data1Image" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
                        
    );
}

export default DemoProduct;