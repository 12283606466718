import React,{useState, useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Aboutpage from "./Aboutpage";
import Servicepage from "./Servicepage";
import Blogpage from "./Blogpage";
import Projectpage from "./Projectpage";
import Teampage from "./Teampage";
import Testimonialpage from "./Testimonialpage";
import Contactpage from "./Contactpage";
import PetroleumTankers from "./PetroleumTankerPage";
import WaterTanker from "./WaterTanker";
import BituminTanker from "./BituminTankers";
import FuelBowsers from "./FuelBowsers";
import AuthForm from "./signin";
import Profile from "./Profile";
import SEO from "./SEO";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import AdminPage from "./Adminpage";
import DemoPage from "./DemoPage";
import Loader from "./Components/Loader";
import ScrollToTop from "./Components/ScrollPage";

const App=()=>{

  
const [metaTitle, setMetaTitle] = useState('');
const [keywords, setKeywords] = useState('');
const [primaryKeywords, setPrimaryKeywords] = useState('');
const [secondaryKeywords, setSecondaryKeywords] = useState('');


useEffect(() => {
  const metaDataRef = firebase.database().ref('metadata');

  // Fetch metadata from the database
  metaDataRef.once('value').then((snapshot) => {
      const metaData = snapshot.val();
      if (metaData) {
          setMetaTitle(metaData.metaTitle || '');
          setKeywords(metaData.keywords || '');
          setPrimaryKeywords(metaData.primaryKeywords || '');
          setSecondaryKeywords(metaData.secondaryKeywords || '');

      }
  }).catch((error) => {
      console.error('Error fetching metadata:', error);
  });
}, []);

  useEffect(() => {



    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);

  return(
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="/about" element={<Aboutpage/>}/>
          <Route path="/service" element={<Servicepage/>}/>
          <Route path="/blog" element={<Blogpage/>}/>
          <Route path="/projects" element={<Projectpage/>}/>
          <Route path="/team" element={<Teampage/>}/>
          <Route path="/testimonial" element={<Testimonialpage/>}/>  
          <Route path="/contact" element={<Contactpage/>}/>
          <Route path="/PetroleumTankers" element={<PetroleumTankers/>}/>
          <Route path="/WaterTanker" element={<WaterTanker/>}/>
          <Route path="/BituminTankers" element={<BituminTanker/>}/>
          <Route path="/FuelBowsers" element={<FuelBowsers/>}/>
          <Route path="/auth" element={<AuthForm/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/SEO" element={<SEO/>}/>
          <Route path="/admin" element={<AdminPage/>}/>
          <Route path="/demoani" element={<DemoPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;