import React, { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import { getColorMode } from "../ColorMode";
import "./ParallexService.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

const PService = () => {
  const [activeTab, setActiveTab] = useState("ServiceTab-1");
  const [data, setData] = useState("");
  const [commercialData, setCommercialData] = useState([]);
  const [residentialData, setResidentialData] = useState([]);
  const [industrialData, setIndustrialData] = useState([]);
  const [safetyCompliance, setSafetyCompliance] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const tabIds = ["ServiceTab-1", "ServiceTab-2", "ServiceTab-3", "ServiceTab-4"];
  const tabNames = ["Custom Fabrication", "Maintenance and Repairs", "Consultation", "Safety Compliance"];
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false); 


  
  const toggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Service");
        console.log('Fetched service data:', response.data); 
        setData(response.data);
        setCommercialData(response.data.Specifi.Commercial);
        setResidentialData(response.data.Specifi.Residental);
        setIndustrialData(response.data.Specifi.Industrial);
        setSafetyCompliance(response.data.Specifi.SafetyCompliance);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();

  }, []);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      
      const startFixed = scrollableHeight * 0.24; // 20% scroll point
      const endFixed = scrollableHeight * 0.50;   // 80% scroll point

      if (scrollPosition >= startFixed && scrollPosition <= endFixed) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  
  useEffect(() => {
    const checkScroll = () => {
      const scrollY = window.scrollY;

     
     

      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollY / totalHeight) * 100;

      // Set the active tab based on scroll percentage
      if (scrollPercent < 30) {
        setActiveTabIndex(0);
      } else if (scrollPercent < 35) {
        setActiveTabIndex(1);
      } else if (scrollPercent < 43) {
        setActiveTabIndex(2);
      } else {
        setActiveTabIndex(3);
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);



  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true, // Enable left and right arrows
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["<", ">"], // Set custom icons for left and right arrows
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };


  const carouselOptions1 = {
    loop: true,
    margin: 10,
    nav: true, // Enable left and right arrows
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["<", ">"], // Set custom icons for left and right arrows
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const carouselOptions2 = {
    loop: true,
    margin: 10,
    nav: true, // Enable left and right arrows
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["<", ">"], // Set custom icons for left and right arrows
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const carouselOptions3 = {
    loop: true,
    margin: 10,
    nav: true, 
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };


  return (
    <section className="container-fluid service bg-white py-5 serviceanimation">
      <div className="container py-5">
        <div className="row servicetwodiv g-5">
        <div
            className={`col-lg-4 wow fadeInLeft projectcontentfixed ${isFixed ? 'fixed' : ''}`}
            data-wow-delay="0.2s"
          >
          <h4 className="text-primary">{data.Title}</h4>
          <h1 className="display-4 mb-4">{data.SubTitle}</h1>
          <button className="toggle-btn" onClick={toggleDescription}>
          {isDescriptionVisible ? "-" : "+"}
        </button>
        <p className={`text-black description-text ${isDescriptionVisible ? "show" : "hide"}`}>
          {data.description}
        </p>

            <ul className="nav">
              {tabIds.map((tabId, index) => (
                <li className="nav-item  bg-white w-100" key={tabId}>
                  <a
                    className={`py-3 px-5  ${activeTabIndex === index ? "active" : ""}`}
                  >
                     <span>{tabNames[index]}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="v2">
          <div className="wow fadeInRight bottom-wrap" data-wow-delay="0.2s">
            <div className="tab-content sticky-wrap">
             
                <div
                  className={`tab-pane fade show active ${activeTabIndex === 0 ? 'active' : ''} projectd-1`}>
                        <div className="projects-item">
                        <OwlCarousel className="owl-theme" {...carouselOptions}>
                            {commercialData.slice(1).map((item, index) => (
                                <div className="item" key={index}>
                          <img src={item.Image} className="img-fluid w-100" alt="" />
                          <div className="projects-content bg-white p-4">
                            <h4 className="mb-3">{item.Title}</h4>
                            <p className=" text-black">{item.description}</p>
                            <a className="btn btn-primary py-2 px-4" href="/service">
                              Read More
                            </a>
                          </div>
                          </div>
                            ))}
                        </OwlCarousel>
                        </div>
                </div>
                <div
                  className={`tab-pane fade show active ${activeTabIndex === 1 ? 'active' : ''} projectd-2`}>
                        <div className="projects-item">
                        <OwlCarousel className="owl-theme" {...carouselOptions1}>
                        {residentialData.slice(1).map((item, index) => (
                            <div className="item" key={index}>
                          <img src={item.Image} className="img-fluid w-100" alt="" />
                          <div className="projects-content bg-white p-4">
                            <h4 className="mb-3">{item.Title}</h4>
                            <p className="mb-4 text-black">{item.description}</p>
                            <a className="btn btn-primary py-2 px-4" href="/service">
                              Read More
                            </a>
                          </div>
                          </div>
                            ))}
                            </OwlCarousel>
                        </div>
                </div>
                <div className={`tab-pane fade show active ${activeTabIndex === 2 ? 'active' : ''} projectd-3`}>
                        <div className="projects-item">
                        <OwlCarousel className="owl-theme" {...carouselOptions2}>
                        {industrialData.slice(1).map((item, index) => (
                            <div className="item" key={index}>
                          <img src={industrialData[1]?.Image} className="img-fluid w-100" alt="" />
                          <div className="projects-content bg-white p-4">
                            <h4 className="mb-3">{industrialData[1]?.Title}</h4>
                            <p className="mb-4 text-black">{industrialData[1]?.description}</p>
                            <a className="btn btn-primary py-2 px-4" href="/service">
                              Read More
                            </a>
                          </div>
                          </div>
              ))}
            </OwlCarousel>
                        </div>
                </div>
                <div className={`tab-pane fade show active ${activeTabIndex === 3 ? 'active' : ''} projectd-4`}>
                        <div className="projects-item">
                        <OwlCarousel className="owl-theme" {...carouselOptions3}>
                        {safetyCompliance.slice(1).map((item, index) => (
                            <div className="item" key={index}>
                          <img src={item.Image} className="img-fluid w-100" alt="" />
                          <div className="projects-content bg-white p-4">
                            <h4 className="mb-3">{item.Title}</h4>
                            <p className="mb-4 text-black">{item.description}</p>
                            <a className="btn btn-primary py-2 px-4" href="/service">
                              Read More
                            </a>
                          </div>
                          </div>
                    ))}
                    </OwlCarousel>
                        </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PService;
