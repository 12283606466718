import React from "react";
import Team1 from "../img/team-1.jpg";
import Team2 from "../img/team-2.jpg";
import Team3 from "../img/team-3.jpg";
import Team4 from "../img/team-4.jpg";
import { getColorMode } from "../ColorMode";

const Team=()=>{

  const colorvar= getColorMode();


  const var1="-Team";
  const var2=colorvar.concat(var1);
  
    return(
<div className={`${var2} container-fluid team pb-5`}>
    <div className="container pb-5">
      <div
        className="d-flex flex-column mx-auto text-center mb-5 wow fadeInUp"
        data-wow-delay="0.2s"
        style={{ maxWidth: 800 }}
      >
        <h4 className="text-primary">Our Team</h4>
        <h1 className="display-4 mb-4">Electricity Service offerings</h1>
        <p className="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem,
          deserunt provident ab voluptates rerum eaque eum magni autem atque in
          minus laboriosam corrupti deleniti voluptatibus rem reiciendis modi
          veniam animi?
        </p>
      </div>
      <div className="row g-4">
        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
          <div className="team-item">
            <div className="team-img">
              <img src={Team1} className="img-fluid w-100" alt="" />
              <div className="team-icon">
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-instagram" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="team-content bg-light text-center p-4">
              <h4>Shoaib Bashir</h4>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
          <div className="team-item">
            <div className="team-img">
              <img src={Team2} className="img-fluid w-100" alt="" />
              <div className="team-icon">
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-instagram" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="team-content bg-light text-center p-4">
              <h4>Shoaib Bashir</h4>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="team-img">
              <img src={Team3} className="img-fluid w-100" alt="" />
              <div className="team-icon">
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-instagram" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="team-content bg-light text-center p-4">
              <h4>Shoaib Bashir</h4>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.8s">
          <div className="team-item">
            <div className="team-img">
              <img src={Team4} className="img-fluid w-100" alt="" />
              <div className="team-icon">
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-instagram" />
                </a>
                <a className="btn btn-square btn-primary mb-2" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="team-content bg-light text-center p-4">
              <h4>Shoaib Bashir</h4>
              <p className="mb-0">Profession</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
}

export default Team;