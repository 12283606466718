import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import image1 from "../img/JVS.png";
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import logo from "../img/JVSLogo-Ico.ico";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user); // Set the current user
      } else {
        setIsLoggedIn(false);
        setUser(null); // No user
      }
    });
    return unsubscribe;
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut().then(() => {
      setIsLoggedIn(false);
      navigate("/");
    });
  };

  const [isAdmin, setIsAdmin] = useState(false);

useEffect(()=>{
  const checkAdminStatus=()=>{
  firebase.auth().onAuthStateChanged((user)=>{
    if(user){
      const userEmail=user.email;
      firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
        .then((snapshot)=>{
          if (snapshot.exists()) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }).catch((error)=>{
          console.error("Error checking admin status:",error);
        })
    }else{
      setIsAdmin(false);
    }

  })
  }
  checkAdminStatus();
},[]);



  return (
    <div className="container-fluid header-top">
     
      <div className="container2 d-flex align-items-center">
      {/* <a href="/" className="navbar-brand d-block d-lg-none" style={{ height: 60,paddingRight: 0 }}>
      <img src={logo} alt="Logo" className="logoimg" style={{ height: 50 }} /> 
      </a> */}
      
        <div className="d-flex align-items-center h-100">
       
      {/* Logo and title for desktop view */}
      <a href="/" className="navbar-brand d-none d-lg-block" style={{ height: 125 }}>
        <h1 className="text-primary mb-0">
          <img src={logo} className="logoimg" alt="Logo" /> JVS Body Works
        </h1>
      </a>
        </div>
        <div className="w-100 h-100">
          <div className="topbar px-0 py-2 d-none d-lg-block" style={{ height: 45 }}>
            <div className="row gx-0 align-items-center">
              <div className="col-lg-8 text-center text-lg-center  blackbox mb-lg-0">
                <div className="d-flex flex-wrap">
                  <div className="border-end border-primary pe-3">
                    <a href="https://maps.app.goo.gl/14b2pwfhhbacK5RA7?g_st=com.google.maps.preview.copy" className="text-muted small">
                      <i className="fas fa-map-marker-alt text-primary me-2" /> Chennai
                    </a>
                  </div>
                  <div className="ps-3">
                    <a href="mailto:info@jvsbw.com" className="text-muted small">
                      <i className="fas fa-envelope text-primary me-2" /> info@jvsbw.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <div className="d-flex justify-content-end">
                  <div className="d-flex border-primary pe-3">
                    <a className="btn p-0 text-primary me-3" href="https://www.facebook.com/profile.php?id=61566451921430"><i className="fab fa-facebook-f" /></a>
                    <a className="btn p-0 text-primary me-3" href="https://x.com/jvsbodyworks"><i className="fab fa-twitter" /></a>
                    <a className="btn p-0 text-primary me-3" href="https://www.instagram.com/jvs_bodyworks?igsh=MTlsYWJrbWgzd2p4eg%3D%3D&utm_source=qr"><i className="fab fa-instagram" /></a>
                    <a className="btn p-0 text-primary me-0" href="https://www.linkedin.com/company/jvsbodyworks/"><i className="fab fa-linkedin-in" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-bar px-0 py-lg-0" style={{ height: 80 }}>
          
            <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-lg-end">
              <a href="/" className="navbar-brand d-block d-lg-none">
                  <img src={logo} alt="Logo" className="logoimg" /> 
                  <span className="headertitlem text-primary ms-2">JVS Body Works</span>
                </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav mx-0 mx-lg-auto bg-white">
                  <NavLink exact to="/" activeClassName="current" className="nav-item nav-link">Home</NavLink>
                  <NavLink to="/about" activeClassName="current" className="nav-item nav-link">About</NavLink>
                  <NavLink to="/service" activeClassName="current" className="nav-item nav-link">Service</NavLink>
                  <div className="nav-item dropdown">
                    <a href="#" className="nav-item nav-link" data-bs-toggle="dropdown">
                      <span className="dropdown-toggle">Products</span>
                    </a>
                    <div className="dropdown-menu">
                      <NavLink to="/PetroleumTankers" activeClassName="current" className="dropdown-item">Petroleum Tankers</NavLink>
                      <NavLink to="/WaterTanker" activeClassName="current" className="dropdown-item">Water Tankers</NavLink>
                      <NavLink to="/BituminTankers" activeClassName="current" className="dropdown-item">Bitumin Tankers</NavLink>
                      <NavLink to="/FuelBowsers" activeClassName="current" className="dropdown-item">Fuel Bowsers</NavLink>
                    </div>
                  </div>
                  <NavLink to="/contact" activeClassName="current" className="nav-item nav-link">Contact</NavLink>
                  <div className="nav-btn ps-3">
                    {isLoggedIn ? (
                      <div className="dropdown">
                        <a href="#" className="btn btn-primary py-2 px-4 ms-0 ms-lg-3 dropdown-toggle" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fas fa-user" /> {/* User icon */}
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                          <li><NavLink to="/profile" className="dropdown-item">Profile</NavLink></li>
                          {isAdmin && <li><NavLink to="/seo" className="dropdown-item">SEO</NavLink></li>}
                          {isAdmin && <li><NavLink to="/admin" className="dropdown-item">Admin Page</NavLink></li>}
                         <li><a href="/" className="dropdown-item" onClick={handleLogout}>Log Out</a></li>
                        </ul>
                      </div>
                    ) : (
                      <a href="/auth" className="btn btn-primary py-2 px-4 ms-0 ms-lg-3">Login</a>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
