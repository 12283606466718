import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import Loader from "./Loader";
import { getColorMode } from "../ColorMode";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WelcomeSection = () => {
  const [slides, setSlides] = useState([]);
  const [slideSpeed, setSlideSpeed] = useState(6500); 

  const colorvar = getColorMode();

  const var3 = "-welcomeslideheader1";

  const var4 = colorvar.concat(var3);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Fetchingslideadmin");
      console.log('Fetched slide data:', response.data); 
      setSlides(response.data.slides || []);
      setSlideSpeed(response.data.slideSpeed ? parseInt(response.data.slideSpeed) * 1000 : 6500);
    } catch (error) {
      console.error('Error fetching data from backend:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: slideSpeed,
    cssEase: "linear",
    fade: true
  };

  return (
    <section className={`${var4} welcomeslideheader1`}>
      <Slider  className="header-carousel" {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="header-carousel-item hero-section">
            <div className="carousel-caption" style={{ backgroundImage: `url(${slide.imageUrl})` }}>
              <div className="container">
                <div className="row g-4 align-items-center">
                  <div className="col-lg-7 animated fadeInLeft">
                    <div className="text-sm-center text-md-start">
                      <h4 className="text-white text-uppercase fw-bold mb-4">
                        {slide.field1}
                      </h4>
                      <h1 className="display-2 text-white mb-4">
                        {slide.field2}
                      </h1>
                      <p className="mb-5 fs-5">
                        {slide.field3}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default WelcomeSection;