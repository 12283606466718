import React,{useEffect, useState} from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { getColorMode } from "./ColorMode";
import "./dynamic.css";
import axios from "axios";
import Loader from "./Components/Loader";

const Contactpage=()=>{

  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Contactinfo");
        console.log('Fetched service data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

  
  const [data1, setData1] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/ContactAddress");
        console.log('Fetched service data:', response.data); 
        setData1(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

  

  useEffect(()=>{
    document.title="Contact | JVS Body Works"
  },[]);

  const colorvar= getColorMode();


  const var1="-mode";
  const var2=colorvar.concat(var1);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    subject: "",
    project: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {

      const updatedFormData = {
        ...formData,
        date: getCurrentDate(),
      };

      
      const response = await axios.post("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/contactform",updatedFormData);
      console.log("Form submitted successfully", response.data);

      setSuccessMessage("Your message has been sent successfully!");
      setErrorMessage("");
     
      setFormData({
        name: "",
        email: "",
        contact: "",
        message: "",
        subject: "",
        project: "",
      });

      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("There was an error sending your message. Please try again.");
    }
  };

    return(
      <div className={var2}>
      <Loader/>
         <meta charSet="utf-8" />
  <title>JVS Bodyworks</title>
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <meta content="" name="keywords" />
  <meta content="" name="description" />
  {/* Google Web Fonts */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
  <link
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
    rel="stylesheet"
  />
  {/* Icon Font Stylesheet */}
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
    rel="stylesheet"
  />
  {/* Libraries Stylesheet */}
  <link rel="stylesheet" href="lib/animate/animate.min.css" />
  <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
  {/* Customized Bootstrap Stylesheet */}
  <link href="css/bootstrap.min.css" rel="stylesheet" />
  {/* Template Stylesheet */}
  <link href="css/style.css" rel="stylesheet" />
  {/* Spinner Start */}
  {/* <div
    id="spinner"
    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
  >
    <div
      className="spinner-border text-primary"
      style={{ width: "3rem", height: "3rem" }}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div> */}
  {/* Spinner End */}
  {/* Navbar & Hero Start */}
            <Navbar/>
            <div className="container-fluid bg-breadcrumb">
  <div className="container text-center py-5" style={{ maxWidth: 900 }}>
    <h4
      className="text-white display-4 mb-4 wow fadeInDown"
      data-wow-delay="0.1s"
    >
      Contact Us
    </h4>
    <ol
      className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
      data-wow-delay="0.3s"
    >
      <li className="breadcrumb-item">
        <a href="/">Home</a>
      </li>
      {/* <li className="breadcrumb-item">
        <a href="#">Pages</a>
      </li> */}
      <li className="breadcrumb-item active text-primary">Contact</li>
    </ol>
  </div>
</div>

{/*Contact*/}
<div className="container-fluid contact bg-light py-5">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
        <div>
          <h4 className="text-primary">Contact Us</h4>
          <h3 className="display-4 mb-4">
            {data.Subtitle}
          </h3>
          <p className="mb-5 contactdesc">
            {data.description1}
          </p>
          <div className="d-flex align-items-center mb-4">
            <a className="btn btn-primary btn-md-square me-3" href="">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="btn btn-primary btn-md-square me-3" href="">
              <i className="fab fa-twitter" />
            </a>
            <a className="btn btn-primary btn-md-square me-3" href="">
              <i className="fab fa-instagram" />
            </a>
            <a className="btn btn-primary btn-md-square me-0" href="">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-inline-flex bg-white w-100 p-4">
                <i className="fas fa-map-marker-alt fa-2x text-primary me-4" />
                <div>
                  <h4>Address</h4>
                  <p className="mb-0">{data1.Location}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="d-inline-flex bg-white w-100 p-4">
                <i className="fas fa-envelope fa-2x text-primary me-4" />
                <div>
                  <h4>Mail Us</h4>
                  <p className="mb-0">{data1.Email}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="d-inline-flex bg-white w-100 p-4">
                <i className="fa fa-phone-alt fa-2x text-primary me-4" />
                <div>
                  <h4>Telephone</h4>
                  <p className="mb-0">{data1.mobile}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
        <div>
          <p className="mb-4 contactdesc">
            {data.description2}
            {/* <a
              className="text-primary fw-bold"
              href="https://htmlcodex.com/contact-form"
            >
              Download Now
            </a> */}
            .
          </p>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              <div className="col-lg-12 col-xl-6">
                <div className="form-floating">
                  <input
                    type="text"
                    name="name"
                    className="form-control border-0"
                    id="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">Your Name</label>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="form-floating">
                  <input
                    type="email"
                    name="email"
                    className="form-control border-0"
                    id="email"
                    placeholder="Your Email"
                    value={formData.email} 
                    onChange={handleChange}
                  />
                  <label htmlFor="email">Your Email</label>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="form-floating">
                  <input
                    type="phone"
                    name="contact"
                    className="form-control border-0"
                    id="phone"
                    placeholder="Phone"
                    value={formData.contact}
                    onChange={handleChange}
                  />
                  <label htmlFor="phone">Your Phone</label>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="form-floating">
                  <input
                    type="text"
                    name="project"
                    className="form-control border-0"
                    id="project"
                    placeholder="Project"
                    value={formData.project}
                    onChange={handleChange}
                  />
                  <label htmlFor="project">Your Project</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input
                    type="text"
                    name="subject"
                    className="form-control border-0"
                    id="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea
                    name="message"
                    className="form-control border-0"
                    placeholder="Leave a message here"
                    id="message"
                    style={{ height: 125 }}
                    defaultValue={""}
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="flexCheck"
                  />
                  <label className="form-check-label" htmlFor="flexCheck">
                    I agree with the site privacy policy
                  </label>
                </div>
              </div> */}
              <div className="col-12">
                <button className="btn btn-primary w-100 py-3">
                  Send Message
                </button>
              </div>
            </div>
          </form>
          {successMessage && (
                <div  className="contactsuccess-message">
                  <p>{successMessage}</p>
                </div>
              )}
              {errorMessage && (
                  <div className="contacterror-message">
                    <p>{errorMessage}</p>
                  </div>
                )}
        </div>
      </div>
      <div className="col-12 wow fadeInUp" data-wow-delay="0.2s">
        <div className="h-100 overflow-hidden">
          <iframe
            className="w-100"
            style={{ height: 400 }}
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3353.4171698057116!2d80.20046817508033!3d13.19381078714238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDExJzM3LjciTiA4MMKwMTInMTEuMCJF!5e1!3m2!1sen!2sin!4v1728452064781!5m2!1sen!2sin"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<Footer/>
        </div>
    );
}
export default Contactpage;