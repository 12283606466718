import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import "./Testimonial.css";
import { getColorMode } from "../ColorMode";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/testimonials");
        const data = response.data;
        // Ensure the testimonials are correctly formatted as an array
        const testimonialsArray = Object.values(data).filter(item => typeof item === 'object');
        setTestimonials(testimonialsArray);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const colorvar= getColorMode();

  const var1="-testimonial";
  const var2=colorvar.concat(var1);

  return (
    <div className={`${var2} container-fluid testimonial bg-dark py-5`}>
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6 col-xl-5">
            <h4 className="text-primary">{testimonials.Subtitle || "Testimonial"}</h4>
            <h1 className="display-4 text-white mb-4">
              {testimonials.Title || "Powerful Praise Heard From Our Customers"}
            </h1>
          </div>
          <div className="col-lg-6 col-xl-7">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className="testimonial-item" key={index}>
                  <div className="testimonial-inner p-4" style={{ height: "200px" }}>
                    <div className="ms-4">
                      <h4>{testimonial.author_name}</h4>
                      <p className="testipara">{testimonial.text}</p>
                      <div className="d-flex text-primary">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
